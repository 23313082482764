import { withAITracking } from '@microsoft/applicationinsights-react-js';
import { Box, Grid, Typography } from '@mui/material';
import Routes from '../../constants/Routes';
import { appInsights, reactPlugin } from '../../helpers/AppInsights';
import { useEffect } from 'react';
import SignInCard from '../register/SignInCard';

export interface IHomePageProps
{
}

export interface IHomePageState
{
}


function HomePage(props:IHomePageProps){
  useEffect(() =>{
    appInsights.trackPageView({name:'HomePage',
                                uri:Routes.home
                                });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[]);

  return(
      <Grid container justifyContent={'center'} alignItems={'center'} maxWidth={'xl'} minHeight={'90vh'} p={2} columnGap={8} rowGap={4}>
        <Grid item  py={2} maxWidth={'sm'} textAlign={'center'}>
          <Typography variant='h1' sx={{fontSize:{xs:'10vw', sm:56, md:64}}}>Data that speaks,</Typography>
          <Typography variant='h1' sx={{fontSize:{xs:'10vw', sm:56, md:64}}}>Text that resonates</Typography>
          <Box sx={{py:{xs:1, sm:1, md:2}}}/>
          <Typography variant='h6'>
            Empower your AI model with meticulously curated speech and text data for Indian Languages
          </Typography>
        </Grid>
        <Grid item >
          <SignInCard/>
        </Grid>
      </Grid>
  )
}


export default withAITracking(reactPlugin,HomePage);