import { Box, Backdrop, CircularProgress } from '@mui/material';
import * as React from 'react';
import { connect } from 'react-redux';
import { Routes as RoutesSwitch,Route} from "react-router-dom";
import { Roles } from '../constants/Roles';
import Routes from '../constants/Routes';
import HomePage from '../pages/landing/HomePage';

const ProjectsPage = React.lazy(() => import('../pages/project/ProjectsPage'));
const ProfileReferenceCard = React.lazy(() => import('../pages/profile/ProfileReferenceCard'));
const SignInPage = React.lazy(() => import('../pages/register/SignInPage'));
const RegistrationPage = React.lazy(() => import('../pages/register/RegistrationPage'));
const ForgotPasswordPage = React.lazy(() => import('../pages/register/ForgotPasswordPage'));
const ResetPasswordPage = React.lazy(() => import('../pages/register/ResetPasswordPage'));
const ContactUsPage = React.lazy(() => import('../pages/landing/ContactUsPage'));
const TermsOfUsePage = React.lazy(() => import('../pages/legal/TermsOfUsePage'));
const PrivacyPolicyPage = React.lazy(() => import('../pages/legal/PrivacyPolicyPage'));
const ProfilePage = React.lazy(() => import('../pages/profile/ProfilePage'));
const AdminPage = React.lazy(() => import('../pages/admin/AdminPage'));
const FeedbackDrawer = React.lazy(() => import('../components/FeedbackDrawer'));

const HomeTracoPage = React.lazy(() => import('../projects/traco/pages/HomeTracoPage'));
const HomeSecoPage = React.lazy(() => import('../projects/seco/pages/HomeSecoPage'));
const HomeQGenPage = React.lazy(() => import('../projects/qgen/pages/HomeQGenPage'));
const HomeCottPage = React.lazy(() => import('../projects/cott/pages/HomeCottPage'));
const HomePostPage = React.lazy(() => import('../projects/post/pages/HomePostPage'));
const HomeEntaPage = React.lazy(() => import('../projects/enta/pages/HomeEntaPage'));
const HomeTranPage = React.lazy(() => import('../projects/tran/pages/HomeTranPage'));
const HomeAutexPage = React.lazy(() => import('../projects/autex/pages/HomeAutexPage'));
const HomeAudverPage = React.lazy(() => import('../projects/audver/pages/HomePage'));
const HomeImtexPage = React.lazy(() => import('../projects/imtex/pages/HomeImtexPage'));


export interface IPageRoutesProps
{
  isUserLoggedIn:boolean,
  userRole:number,
  userRefCheck:boolean,
  feedbackDrawerOpen:boolean
}

export interface IPageRoutesState
{
}

class PageRoutes extends React.Component<IPageRoutesProps,IPageRoutesState> {

  constructor(props:IPageRoutesProps)
  {
    super(props);
    this.state={
    };
  }

  public render() {
    return (
        <React.Suspense fallback = {
          <Box>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}>
                <CircularProgress color="inherit" />
              </Backdrop>
          </Box>}>
              <RoutesSwitch >

                <Route path={Routes.home} element = {
                  this.props.isUserLoggedIn ? (
                    this.props.userRefCheck ? <ProjectsPage/> : <ProfileReferenceCard/>)
                  :<HomePage/>
                }/>

                <Route  path={Routes.admin} element = {this.props.isUserLoggedIn && 
                                                      this.props.userRole === Roles.ADMIN?
                                                      <AdminPage/>:<SignInPage/>}
                />

                <Route  path={Routes.contact} element = {<ContactUsPage />}/>
                <Route  path={Routes.signIn} element = {<SignInPage />}/>
                <Route  path={Routes.register} element={<RegistrationPage />}/>
                <Route  path={Routes.forgotPassword} element={<ForgotPasswordPage/>}/>
                <Route  path={Routes.resetPassword} element={<ResetPasswordPage />}/>
                <Route  path={Routes.termsOfUse} element = {<TermsOfUsePage />}/>
                <Route  path={Routes.privacyPolicy} element = {<PrivacyPolicyPage/>}/>

                <Route  path={Routes.traco+"/:pId"} element = {this.props.isUserLoggedIn?<HomeTracoPage/>:<SignInPage/>}/>
                <Route  path={Routes.seco+"/:pId"} element = {this.props.isUserLoggedIn?<HomeSecoPage/>:<SignInPage/>}/>
                <Route  path={Routes.qgen+"/:pId"} element = {this.props.isUserLoggedIn?<HomeQGenPage/>:<SignInPage/>}/>
                <Route  path={Routes.cott+"/:pId"} element = {this.props.isUserLoggedIn?<HomeCottPage/>:<SignInPage/>}/>
                <Route  path={Routes.post+"/:pId"} element = {this.props.isUserLoggedIn?<HomePostPage/>:<SignInPage/>}/>
                <Route  path={Routes.enta+"/:pId"} element = {this.props.isUserLoggedIn?<HomeEntaPage/>:<SignInPage/>}/>
                <Route  path={Routes.tran+"/:pId"} element = {this.props.isUserLoggedIn?<HomeTranPage/>:<SignInPage/>}/>
                <Route  path={Routes.autex+"/:pId"} element = {this.props.isUserLoggedIn?<HomeAutexPage/>:<SignInPage/>}/>
                <Route  path={Routes.audver+"/:pId"} element = {this.props.isUserLoggedIn?<HomeAudverPage/>:<SignInPage/>}/>
                <Route  path={Routes.imtex+"/:pId"} element = {this.props.isUserLoggedIn?<HomeImtexPage/>:<SignInPage/>}/>
                
                <Route  path={Routes.account} 
                        element = {this.props.isUserLoggedIn?
                                  <ProfilePage route={Routes.account} name changePassword basic location language payment p={2}/>
                                  :<SignInPage/>}
                  />

                <Route path={Routes.base} element = {
                    this.props.isUserLoggedIn ? (
                      this.props.userRefCheck ? <ProjectsPage/> : <ProfileReferenceCard/>)
                   :<HomePage/>
                  }/>

              </RoutesSwitch>
              {this.props.feedbackDrawerOpen && <FeedbackDrawer/>}
        </React.Suspense>
    );
  }
}


export const mapStateToProps = (state:any)=>{
  return {
    isUserLoggedIn:state.userDetailsReducer.isUserLoggedIn,
    userRole:state.userDetailsReducer.user?.role,
    userRefCheck:state.userDetailsReducer.user?.reference?.length>0,
    feedbackDrawerOpen:state.systemReducer.feedbackDrawerOpen,
  }
}  

export const mapDispatchToProps = (dispatch:any)=>{
  return {
  }
}

export default connect(mapStateToProps,mapDispatchToProps)(PageRoutes);