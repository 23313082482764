const Routes = {
    base:"/",
    home:"/home",
    aboutUs:"/about-us",
    contact:"/contact",
    signIn:"/sign-in",
    register:"/register",
    forgotPassword:"/forgot-password",
    resetPassword:"/reset-password",
    termsOfUse:"/terms-of-use",
    privacyPolicy:"/privacy-policy",
    account:"/account",
    admin:"/admin",
    payment:'/payment',
    
    seco:"/seco",
    traco:"/traco",
    cott:"/cott",

    qgen:"/qgen",
    post:"/post",
    enta:"/enta",
    tran:"/tran",
    autex:"/autex",
    audver:"/audver",
    imtex:"/imtex"
}

export default Routes;